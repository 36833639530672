function initMainSlider() {
	if ($('.bxslider').length) {
		const pause = $('.bxslider').data('pause') || 3000
		$('.bxslider').bxSlider({
	 			adaptiveHeight: true,
				mode: 'fade',
				pager: true,
				nextText: "",
				prevText: "",
				auto: true,
				pause: pause
		});
	}
}

function turbolinksInitMainSlider() {
	initMainSlider();
}

$(window).on("turbolinks:load", turbolinksInitMainSlider);
