function bindFooterMenuClick() {
	$(".c-footer-nav__toggle-menu\\@delta").click(function(){
		$(this).toggleClass('u-reverse-icon');
		if ( $('.c-footer-nav__link--menu\\@delta').hasClass('slide-down')) {
			$('.c-footer-nav__link--menu\\@delta').removeClass('slide-down');
			$('.c-footer-nav__link--menu\\@delta').addClass('slide-up');
			$('.c-footer-nav__link--menu\\@delta').animate({bottom: '0'}, 500);
		}
		else  {
			$('.c-footer-nav__link--menu\\@delta').removeClass('slide-up');
			$('.c-footer-nav__link--menu\\@delta').addClass('slide-down');
			$('.c-footer-nav__link--menu\\@delta').animate({bottom: '44px'}, 500);
		}
	});
}

function turbolinksInitFooterMenu() {
	bindFooterMenuClick();
};

$(window).on("turbolinks:load", turbolinksInitFooterMenu);