function hidePagerIfOne(slider) {
	if(slider.getSlideCount && slider.getSlideCount() < 2) {
		slider.parent().siblings('.bx-controls').find('.bx-pager').hide();
	}
}

function setOffsetFromSlider(){
	var delta = Modernizr.mq('(max-width: 480px)');
	var dynamicPages = $('.o-wrapper').hasClass('o-wrapper-has-sidebar');
	if(delta && dynamicPages) {
	 	var offset = $('.c-img--head').height();
	 	$('.o-main__content').css({paddingTop: offset + 15});
	}
}
var headImgSlider;
function turoblinksInitCustomSlider(){
	headImgSlider = $('.c-img--head__slider').bxSlider({
		nextText: "",
		prevText: "",
		adaptiveHeight: true,
		auto: ($('.c-img--head__slider').children().length < 2) ? false : true,
		pause: 3000,
		onSliderLoad: function(){
			window.dispatchEvent(new Event('loadedSlider'));
			setOffsetFromSlider();
		}
	});

	setOffsetFromSlider();

	//hides pager controls if there is one or no slides
	//adding global event listener for loaded slider event that is broadcasted from
	//bxSlider onSliderLoad callback for c-img--head__slider instance
	window.addEventListener('loadedSlider', hidePagerIfOne(headImgSlider), false);
	$(window).on('resize', setOffsetFromSlider);
}

$(window).on("turbolinks:load", turoblinksInitCustomSlider);
