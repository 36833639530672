function turbolinksInitAccordion() {
	//needs to be inside the function
	//because it is a closure,
	//function needs to access the accordionFirst
	//variable
	function setAccordion(){
		var delta = Modernizr.mq('(max-width: 480px)');
		if(delta && !accordionFirst) {
			accordionFirst = true;
			$('.o-main__subsection--folded\\@delta').append("<a class='c-toggle-accordion' href='javascript:void(0)'><i class='fa fa-angle-down'></i></a>");
			$('.o-main__subsection--folded\\@delta > h5, .c-toggle-accordion').click(function(){
				if ($(this).hasClass('.c-toggle-accordion') ) {
					$(this).siblings('h5.u-text-blue').siblings().not('.c-toggle-accordion,h5.u-text-blue').slideToggle(500);
					$(this).toggleClass('c-toggle-accordion--reverse');
				}
				else {
					$(this).siblings().not('.c-toggle-accordion,h5.u-text-blue').slideToggle(500);
					$(this).siblings('.c-toggle-accordion').toggleClass('c-toggle-accordion--reverse');
				}
				
			});
		}
		else if(!delta && accordionFirst) {
			$('.o-main__subsection--folded\\@delta > .c-toggle-accordion').remove();
			$('.o-main__subsection--folded\\@delta > h5').unbind('click');
			accordionFirst = false;
		}
	}

	var accordionFirst = false;
	setAccordion();
	$(window).on('resize', setAccordion);
}

$(window).on("turbolinks:load", turbolinksInitAccordion);