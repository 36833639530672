function initBrandSlider(){
	 var delta = Modernizr.mq('(max-width: 480px)');
	 var options = {
	 		adaptiveHeight: true,
	 		nextText: "",
			prevText: "",
			slideWidth: 240,
			minSlides: 3,
			maxSlides: 3,
			pager: false,
			auto: false,
			speed: 1500
	};
	
	if (delta) {
	 	$('.brand-slider').bxSlider(options);
	 	$('.brand-list').bxSlider(options);
	}
	else {
	 	$('.brand-slider').bxSlider({
	 		adaptiveHeight: true,
			nextText: "",
			prevText: "",
			slideWidth: 240,
			minSlides: 7,
			maxSlides: 7,
			pager: false,
			auto: false,
			speed: 1500,
      touchEnabled: false
		});
	}
}

function turbolinksInitBrandSlider() {
	initBrandSlider();
}

$(window).on("turbolinks:load", turbolinksInitBrandSlider);

document.addEventListener("turbolinks:load", function() {
	if(window.navigator.userAgent.toLowerCase().indexOf("chrome") > 0) {
    $("body").on("mousedown", ".bx-viewport a", function(event) { 
      /* Only trigger click on left and middle mouse button */
      if( event.which == 1 || event.which == 2 ) {
        if( $(this).attr("href") && $(this).attr("href") != "#" ) {
          window.open( $(this).attr("href"), '_blank' );
        }
      }
    }); 
	}
})

