function handleContactForm() {
    var _timeout = null;

    let successDialog = $("#contact_success_dialog").dialog({
        autoOpen: false,
        dialogClass: 'contact_success_dialog',
        modal: true,
        draggable: false,
        resizable: false,
        open: function (event, ui) {
            $(event.target).dialog('widget')
                .css({position: 'fixed', width: '80%', maxWidth: '900px', minHeight: '200px'})
                .position({my: 'center', at: 'center', of: window});
        },
    });

    // $("#contact_form").on('submit', async function(e) {
    //   const hiddenInput = document.getElementById('g-recaptcha-response-data-contact')
    //   e.preventDefault();
    //   if (hiddenInput){
    //       const response = await grecaptcha.execute(site_key_v3, {action: 'contact'})
    //       hiddenInput.value = response
    //   }
    //   $(e).submit()
    // });
    

    /*$("#contact_form, #workholding_contact_form").on("ajax:success", function (evt) {
        let _this = $(this);
        const recaptchaContainer = $(evt.target).find('.recaptcha').attr('id')
        let recaptchaDiv = $(`#${recaptchaContainer}`)
        hideStatus(_this);
        let detail = evt.detail;
        let data = detail[0], status = detail[1], xhr = detail[2];
        if (data.success) {
            if ($(evt.target).find('.download-resource').length){
              $(evt.target).find('.download-resource')[0].click()
            }

            let modalContainer = _this.closest('.modal')
            if (modalContainer.length){
              _this.trigger("reset");
              $('.modal-backdrop').remove();
              modalContainer.modal('hide')
              $('body').removeClass('modal-open');
            }else{
              showSuccess(_this);
            }
        } else {
            showErrors(_this, data.errors);
            if (data.errors.captcha  && !data.errors.captcha[0]){
              let recaptcha_id = recaptchaDiv.attr('recaptcha_id')
              if (recaptcha_id){
                grecaptcha.reset(recaptcha_id);
              }else{
                recaptchaDiv.html('')
                recaptcha_id = grecaptcha.render(recaptchaContainer, {'sitekey': "<%= Recaptcha.configuration.site_key %>", 'theme': 'light'}); 
                recaptchaDiv.attr('recaptcha_id', recaptcha_id);
              }
            }else{
                //grecaptcha.execute(site_key_v3, {action: 'contact'})
            }
        }
    });*/


    function showErrors($form, errors) {
        $form.find('.field-error').remove();
        if (typeof (errors) === 'object') {
            for (var error in errors) {
                if (errors.hasOwnProperty(error)) {
                    handleError($form, error, errors[error]);
                }
            }
        } else if (typeof (errors) === 'string') {
            console.log(errors);
            $('.contact-submit-row').append("<div class='col-sm-9 error-msg'>" + errors + "</div>");
            _timeout = setTimeout(function () {
                hideErrorMsg($form);
            }, 3000);
        }
    }

    function handleError($form, field, desc) {
        var jqField = $form.find('#contact_' + field);
        var errMsg = $('<p class="text-danger field-error">Please input missing information</p>');
        jqField
            .addClass('error')
            .after(errMsg);
    }

    function showSuccess($form) {
        $form.trigger("reset");
        successDialog.dialog('open');
    }

    function hideErrors($form) {
        $form.find('p.error').remove();
        $form.find('.error').removeClass('error');
    }

    function hideErrorMsg($form) {
        $form.find('div.error-msg').remove();
    }

    function hideSuccess($form) {
        $form.find("div.success").remove();
    }

    function hideStatus($form) {
        if (_timeout) clearTimeout(_timeout);
        hideErrors($form);
        hideSuccess($form);
    }

    $(".open-email-modal").on('click', function () {
        $('#contact-footer')[0].scrollIntoView();
        $('#overlay').show();
    });

    $('#close-overlay').on('click', function () {
        $('#overlay').hide();
    });
    $('#overlay').on('click', function () {
        $('#overlay').hide();
    });

}

$(window).on("turbolinks:load", handleContactForm);

