function resizeTiles() {
	var windowWidth = $(window).width();
	var max = 0;
	$('.c-tile__content').each(function(){
		var text = $(this).find('.c-tile__text').height();
		if( text > max) { max = text; }
	});
	
	$('.c-tile__content').each(function(){
		$(this).css({height: max + 30});
	});
}

function turbolinksInitTilesMatch() {
	resizeTiles();
	$(window).on('resize', resizeTiles);
};

$(window).on("turbolinks:load", turbolinksInitTilesMatch);