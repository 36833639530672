function initHomeSlider() {
  if ($('.home-slider-v2').length) {
    const pause = $('.home-slider-v2').data('pause') || 3000
    $('.home-slider-v2').bxSlider({
      wrapperClass: 'bx-wrapper home-slider-wrapper',
      controls: false
        // adaptiveHeight: true,
        // mode: 'fade',
        // pager: true,
        // nextText: "",
        // prevText: "",
        // auto: true,
        // pause: pause
    });
  }
}

function turbolinksInitHomeSlider() {
  initHomeSlider();
}

$(window).on("turbolinks:load", turbolinksInitHomeSlider);