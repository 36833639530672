$(function() {
  if ( $('.mobile-products').length ) {
    
    var $grid = $( '.mobile-products' ).isotope({
      itemSelector: '.mobile-products-item',
      layoutMode: 'masonry'
    });

    $( '.mobile-products img' ).one( 'load' ,function() {
      $( this ).parents( '.mobile-products' ).isotope();
    });
  }

  $( '.mobile-brands' ).on( 'click', '.mobile-brands-item', function() {
    var filterValue = $( this ).attr( 'data-filter' );
    if ( $( this ).hasClass( 'selected' ) ) filterValue = '';

    $grid.isotope({ filter: filterValue });

    $( '.mobile-brands-item' ).removeClass( 'selected' );
    if ( filterValue != '' ) $( this ).addClass( 'selected' );
  });
});