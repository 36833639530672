
$(function() {
    $("#hawe-image").click(function () {
        $("#hawe-dialog").dialog({
            modal: true,
            dialogClass: 'hawe-dialog',
            draggable: false,
            resizable: false,
            width: '75%'
        });
    })
});

document.addEventListener("turbolinks:load", function(event) {
  if (typeof ga === "function") {
    ga('create', 'UA-80993231-1', 'auto');
    ga("set", "page", location.pathname);
    ga('send', 'pageview');
  }
});
