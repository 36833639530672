function generateTooltips(){
	$('.c-popup-toggle').each(function(){
		
		var closeBtn = "<i data-close='" + $(this).data("name") + "' class='fa fa-close tooltip-close'></i>";
		var tooltipImg = "<img src='" + $(this).data("imgurl") + "'/>";
		var tooltipTitle = "<p>" + $(this).data("title") + "</p>";

		var contentString = closeBtn + tooltipImg + tooltipTitle;
		var content = $(contentString);
		var tooltip = $(this).tooltipster({
			content: content,
			animation: 'fade',
			trigger: 'click',
			theme: 'industrial-tooltip',
			interactive: true,
			onlyOne: true,
			functionReady: function(){
				$('.tooltip-close').on('click',function(){
					$("[data-name='" + $(this).data('close') + "']").tooltipster('hide');
				});
			},
			functionBefore: function(origin, continueTooltip) {
				$('body').addClass('industiral-tooltip-shown');
				$('body').append('<div class="industrial-tooltip-backdrop"></div>');
				$('.industrial-tooltip-backdrop').animate({opacity: 1}, 250);
				continueTooltip();
			},
			functionAfter: function() {
				$('.industrial-tooltip-backdrop').animate({opacity: 0}, 100).remove();
				$('body').removeClass('industiral-tooltip-shown');
			}
		});
	});
}

function turbolinksInitTooltip() {
	if( $.fn.tooltipster ) {
		$.fn.tooltipster('setDefaults', {
		  position: 'right'
		});
	}
	generateTooltips();
}

$(window).on("turbolinks:load", turbolinksInitTooltip);