function isMenuOpen() {
	return $('body').hasClass('menu-open');
};

function openMenu(menu) {
	$('body').removeClass('menu-open');
	menu.animate({left: '100%'}, 500, function(){
		menu.hide();
	});
};

function closeMenu(menu) {
	$('body').addClass('menu-open');
	menu.show();
	menu.animate({left: '50%'}, 500);
};

function toggleMenu(){
	var menu = $('.o-header__nav');
	isMenuOpen() ? openMenu(menu) : closeMenu(menu);
};


function turbolinksInitMenuEvents() {
	$(document).ready(function(){
		$('.c-toggle-menu').on('click', toggleMenu);
	});

	$(document).on('touchstart mouseup', function(e){
		var menu = $('.o-header__nav');
	    if (isMenuOpen(menu) && !menu.is(e.target) && menu.has(e.target).length === 0){
	        toggleMenu(menu);
	    }
	});
}

$(window).on("turbolinks:load", turbolinksInitMenuEvents);
	

